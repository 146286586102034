import { objToUrlSearch } from 'utils/url';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { fetchApi } from 'services/helpers';
import { PROVINCES, HERE_API_KEY, HERE_AUTOCOMPLETE_API_URL, HERE_LOOKUP_API_URL,HERE_BROWSE_API_URL,HERE_GEOCODE_API_URL,HERE_REVERSE_GEOCODE_API_URL} from 'config/constants';

const PROVINCE_CODES = PROVINCES.map(([, code]) => code);

const fetchHere = async (url, params = {}) => {
	const query = objToUrlSearch({
		...params,
		apiKey: HERE_API_KEY
	});

	const fullUrl = `${url}${query}`;
	return fetchApi(fullUrl, json => get(json, 'error_description'));
};
const autoComplete = async query => fetchHere(HERE_AUTOCOMPLETE_API_URL, query);

export const autoCompleteLocation = async ({ search }) => {
	const { items } = await autoComplete({
		q: search === ''? 'toronto': search,
		limit: 20,
		in: 'countryCode:CAN',
		types:'area'

	});

	const locations = 
		(items || [])
			.filter(suggestion => suggestion.localityType == 'city')
			.map(suggestion => {
				const { address, localityType, id } = suggestion;
				let { stateCode: province } = address;
				if(!PROVINCE_CODES.includes(province)) {
					const foundProvince = PROVINCES.find(([{ en, fr }]) => en === province || fr === province);

					if(foundProvince)
						province = foundProvince[1];
					else
						return null;
				}

				return {
					city: address[localityType],
					province,
					locationId: id

				};
			})
			.filter(location => !!location);

	return uniqBy(locations, ({ city, province }) => city + province);
};

	

const geocodeLocation = async locationId => fetchHere(HERE_LOOKUP_API_URL, {
	id: locationId
});

const getPositionForLocation = result => {
	//console.debug("getPositionForLocation's result:",result);
	const position = get(result, 'position');
	//console.debug("getPositionForLocation's position:",position);
	if(position) {
		return {
			latitude: position.lat,
			longitude: position.lng
		};
	}
	return null;
};
const getPositionForProvince = result => {
	//console.debug("getPositionForProvince's result:",result);
	const position = get(result, 'items[0].position');
	//console.debug("getPosition's position:",position);
	if(position) {
		return {
			latitude: position.lat,
			longitude: position.lng
		};
	}
	return null;
};
export const geocodeLocationPosition = async ({ locationId }) => {
	if(locationId)
		return getPositionForLocation(await geocodeLocation(locationId));

	return null;
};

const geocodeProvince = async province => fetchHere(HERE_GEOCODE_API_URL, {
	qq: 'state=' + province,
	in: 'countryCode:CAN'
});

export const geocodeProvincePosition = async ({ province }) => getPositionForProvince(await geocodeProvince(province));

const reverseGeocode = async ({ latitude, longitude }) => fetchHere(HERE_REVERSE_GEOCODE_API_URL, {
	at: `${latitude},${longitude}`,
	types: 'address',
	limit: 1
});

export const reverseGeocodeProvince = async ({ latitude, longitude }) => {
	const result = await reverseGeocode({ latitude, longitude });

	const province = get(result, 'items[0].address.stateCode');
	if(PROVINCE_CODES.includes(province))
		return province;

	return null;
};