export * from './localization';
export * from './payments';

export const MEDIA_BUCKETS = [
	'cars-n-jobs.s3.amazonaws.com',
	'media-carsandjobs-com.s3.amazonaws.com',
	'media-test-carsandjobs-com.s3.amazonaws.com'
];

export const DEFAULT_BUTTON_TEXT = 'Read more';
export const DEFAULT_SUBHEADING = 'It\'s about what moves you';
export const DEFAULT_HEADLINE = 'Cars and Jobs';
export const DEFAULT_CTA_LINK = '/';

export const LOAD_COUNT = 25;

export const PUBLIC_URL = window.PUBLIC_URL;

export const DEFAULT_IMAGE_URL = `${PUBLIC_URL}/logo_carsandjobs.png`;
export const DEFAULT_DESCRIPTION = 'Cars and Jobs';

// export const HERE_APP_CODE = '7lXN1n2DL2CwUcbjQXRB';
// export const HERE_APP_ID = 'sYneTOutyGkFxHJHyLHqwYCra6YZyDYBQj0YqTCPwEk';
export const HERE_API_KEY = 'sYneTOutyGkFxHJHyLHqwYCra6YZyDYBQj0YqTCPwEk';

//export const HERE_AUTOSUGGEST_API_URL = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
export const HERE_AUTOCOMPLETE_API_URL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
export const HERE_LOOKUP_API_URL = 'https://lookup.search.hereapi.com/v1/lookup';
export const HERE_BROWSE_API_URL = 'https://browse.search.hereapi.com/v1/browse';
export const HERE_REVERSE_GEOCODE_API_URL = 'https://revgeocode.search.hereapi.com/v1/revgeocode';
export const HERE_GEOCODE_API_URL = 'https://geocode.search.hereapi.com/v1/geocode';




export const INDEED_CONVERSION_ID = '3353804118991705';

export const POSTING_EDIT_DAYS = 3;

export const REFRESH_TOKEN_INTERVAL = 30 * 60 * 1000;

export const CONTACT_ADDRESS = {
	dealer: '**CADA**\\\n123 Commerce Valley Dr. East,\\\nSuite 303\\\nThornhill, ON L3T 7W8',
	tada: '**TADA**\\\n50 Leek Crescent, Suite 2B\\\nRichmond Hill,\\\nOntario, L4B 4J3'
};
